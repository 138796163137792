
const MatenimientoNacion = () => {



  return (
 <div>

    <div class="card text-center mb-3 mt-4" >
    <div class="card-body">
        <h5 class="card-title">AVISO!</h5>
        <figure>
            <blockquote class="blockquote">
                <p>Servicio en proceso de actualización.</p>
            </blockquote>
            <figcaption class="blockquote-footer">
                <cite title="Source Title">Los servidores del Ministerio de Educación de la Nación se encuentran fuera de servicio hasta: 01/03/2025</cite>
            </figcaption>
        </figure>

    </div>
    </div>

 </div>
        
  );
};

export default MatenimientoNacion;
