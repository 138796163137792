import React, { useState, useEffect} from 'react';

import Generico from './Generico';

import 'bootstrap/dist/css/bootstrap.min.css';

import imgVerAgente from '../assets/img/veragente.png';
import imgServidor from '../assets/img/cambiardirservidor.jpg';

import Agente from '../assets/software/ThefClien4.8.exe';

const urlDescargaAgente = "https://www.youtube.com/embed/LQtBOiJ50qU?si=oGMUkmZ-6B8DiMrO" 
const urlAplicarPDP = "https://www.youtube.com/embed/ypw3f4RbtEA?si=zW-NyjywGLZgIxDT"


export const Sponsor = (props) =>{

  const { huesped } = props;
  const [idSesion, setIdSesion] = useState(null);
  const [ultimaBusqueda, setUltimaBusqueda] = useState('');

 const options = ['81CD781AF38F61C49A42', 'FE065AB52323227ADEB8'];
  
  const respuestaInicial = {
    detalle: '',
    id: '',
    request: '',
    respuesta: '',
    solucion: ''
  }

  const [SN, setSN] = useState('');
  const [hwid, setHwid] = useState('');
  const [bt, setBt] = useState('');
  
  const [data, setData] = useState(respuestaInicial);

  const [btDisabled, setBTstate] = useState(false);

  
  let contSolucion = document.querySelector('#contSolucion')

  let contPDP = document.querySelector('#contPDP')
  let contCODIGO = document.getElementById('contCODIGO')
  let contAgente = document.getElementById('contAgente')
  let contNull = document.getElementById('contNull')


  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };


  const seleccionHWID = (e) => {

     console.log(e.target.value)

     setBTstate(false)
     setData(respuestaInicial) 
     setHwid('');
     setBt('');

     if(e.target.value === 'Otro'){
      console.log(e.target.value);
      setSN(e.target.value);

      }else{
        
        console.log(e.target.value);
        setSN(e.target.value);
      }
  }

  const cambioHWID = (e) =>{
    setData(respuestaInicial) 
    setHwid(e.target.value);

  }

  const cambioBT = (e) =>{
    setData(respuestaInicial) 
    setBt(e.target.value);

  }
 

  const obtenerSolucion = async(e) => {

    e.preventDefault();

    if (ultimaBusqueda === '' ) {
        setUltimaBusqueda(hwid)
      }else{
         if(ultimaBusqueda !== hwid){
           setUltimaBusqueda(hwid);
           obtenerNuevoIdSesion()
        }
      }
  

    setBTstate(true)
    setData(respuestaInicial) 

    console.log('SN:', SN);
    console.log('HWID:', hwid);
    console.log('BT:', bt);

    //let snSponsor = 'FE065AB52323227ADEB8'

    let PARAMETROS = '?SN='+SN+'&'+'HWID='+hwid+'&'+'BT='+bt

    if (!hwid || !bt) {
      setBTstate(false)
      alert('Por favor, complete todos los campos.');
      return;
    }else{

     buscarSolucion(PARAMETROS);

    }
  };

  //Solo deberia funcionar cuando da codigo

  const obtenerSolucionPDP = (e) =>{
    
    e.preventDefault();
    setBTstate(true)

    setData(respuestaInicial) 
    console.log(data)
    data.detalle = 'carga';
    console.log(data)
    setData(data) 

    let PARAMETROS = '?SN='+SN+'&'+'HWID='+hwid+'&'+'BT='+bt+'&'+'SOLICITUD=PDP'

    buscarSolucion(PARAMETROS)

  }

  const buscarSolucion = async (PARAMETROS) => {

      console.log(PARAMETROS) 

      setData(respuestaInicial) 
      //console.log(data)
      data.detalle = 'carga';
      //console.log(data)
      setData(data) 
  
      controlSolucion(data)
      

      contNull.classList.add('d-none')
      contCODIGO.classList.add('d-none')
      contAgente.classList.add('d-none')
      contPDP.classList.add('d-none')


       await fetch('https://script.google.com/macros/s/AKfycbxBbYHBuHjqMPhGvwpY-RiqU3pTXOwbl-ibg6Lp3sRHiSJGS0uShno4vyF8iAkvy_nMAw/exec'+PARAMETROS)
       .then(response => response.json()) 
       .then(data => { 

           setBTstate(false)
           console.log(data)
           setData(data) 
/*

      detalle: "El codigo de desbloqueo es: 0613612074"
      *request: true
      respuesta: "0613612074"
      *solucion: "CODIGO"

      archivo: "AgAH0DI0MDExMDc8D8F56scP6LR7/HRHEjmKRTLDBv+YUTJ4OEh3Dvc1ErAIuMRfYA5VDGWtzpdU2kkyJGV07KMsuUt5IiF1SssAEbgqrHCOuSu+SXcIglIQ4//OuxvdhWdnVks1i1EgX0YofRdXSGNZFNNpiUSdU0uZV/C8qWe1GP80dCwxj66TOdV3BJriOlcDvOBitR+FWWWW3dOaACSdLkHbmoab4g+dtDIyVaBKiU6/bAuJ5yHAmjizk+C7Osj87syOCi1miY0ED4YuEV+XMKosAF7h+WLOLdhDgyy3b5UbiSAWJa872qUuw0NO97iwi0NyPPgtYp34x4rfRiY/UZCge8NpNSw="
      detalle: "Archivo generado con exito"
      nombre: "tcopp.bin"
      *request: true​
      *solucion: "PDP"

      id: ": 0x0002"
      *request: false
      respuesta: ""
      *solucion: "No obtuvimos respuesta del servidor"

      detalle: "Error al generar PDP, vuelva a intentar más tarde"
      error_code: 101
      id: ": 0x0003"
      *request: false
      respuesta: 532
      *solucion: "Paquete de migracion faltante, o la información proporcionada no es correcta."
      ultimaSincro: 532

      detalle: "Error al generar PDP, vuelva a intentar más tarde"
      error_code: 101
      id: ": 0x000101"
      request: false
      respuesta: 534
      solucion: "Paquete de migracion faltante, o la información proporcionada no es correcta."
      ultimaSincro: 534


      request:
      solucion:
      detalle:
      respuesta:
      error_code
      id:
      nombre:
      archivo:
      ultimaSincro:

InterDesbloq
      InterDesbloq.clave1
*/
           if(data.request === false){

            if(data.error_code == undefined){
            
              data.solucion = 'No obtuvimos respuesta del servidor'
              data.id = ': 0x0002'
              data.respuesta = ''
              controlSolucion(data)
  
             }else{

              data.solucion = 'Paquete de migracion faltante, o la información proporcionada no es correcta.'
              data.id = ': 0x000'+data.error_code
              data.respuesta = data.ultimaSincro

              controlSolucion(data)

             }
  
           }else{              
              controlSolucion(data)
           }


        })
        
       .catch(error => console.error('Error fetching data for Option 1:', error));  
  
       
       
  };

 const controlSolucion = async(data) =>{

  modificarSesion({

    sn: SN, 
    hwid: hwid, 
    bt: bt, 
    solucion: data.solucion,
    respuesta: data.respuesta
   

  })

  let contPaquete = document.getElementById('contPaquete')
  let pdpSolucion = document.getElementById('pdpSolucion')
  let codigoSolucion = document.getElementById('codigoSolucion')
  let contNull = document.getElementById('contNull')

  contCODIGO.classList.add('d-none')
  contAgente.classList.add('d-none')
  contPDP.classList.add('d-none')
  contNull.classList.add('d-none')

  if(data.solucion === 'PDP' || data.solucion === 'MIGRACION' ){


    contPDP.classList.remove('d-none')
    contAgente.classList.remove('d-none')
    pdpSolucion.classList.add('d-none')

    const btn_desbloqueo = downloadBase64File(data.archivo, data.nombre);

    contPaquete.innerHTML = ''
    contPaquete.appendChild(btn_desbloqueo)

      modificarSesion({
        pdp: data.archivo, 
      })
   

  }else if(data.solucion === 'CODIGO'){

    codigoSolucion.classList.remove('d-none')
    contCODIGO.classList.remove('d-none')
    contAgente.classList.remove('d-none')
    codigoSolucion.classList.remove('d-none')
    console.log(data.respuesta)

   
  }

  if( data.solucion === 'MIGRACION' ){

    pdpSolucion.classList.add('d-none')
    codigoSolucion.classList.add('d-none')

  }


 }
 
 
function downloadBase64File(contentBase64, fileName) {
   
    const linkSource = `data:application/zip;base64 ,${contentBase64}`;
    const downloadLink = document.createElement('a');
  
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.id = "a_tcopp"
    downloadLink.setAttribute("class", "btn btn-outline-success");
    downloadLink.setAttribute("type", "button")
    downloadLink.innerText = "Descargar"
  
    return downloadLink; 
  };


  useEffect(() => {

    const contentElement = document.querySelector('#bottomOf');
    if (contentElement) {
      contentElement.scrollIntoView({ behavior: 'smooth' });
    }

  })

  useEffect(() =>{

    console.log(huesped)
    const inicioExitoso = iniciarSesion(
      {
        modelo: huesped, 

      }
    );

    if (inicioExitoso) {
      console.info('ctr OK');
    } else {
      console.error('No se pudo iniciar sesión. Modificar sesión no se ejecutará.');
    }
  },[])
  
  
  const iniciarSesion = async (data) => {


      const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      };

      try {
          const response = await fetch('https://pjb.edu.ar/api/ctrdesbloqueos/g9_g5', options);
          if (!response.ok) {
              throw new Error(`Error: ${response.status} ${response.statusText}`);
          }

          const result = await response.json();

          if (result && result.data) {

              const id = result.data.id;

              setIdSesion(id); // Establece el ID de sesión
              console.log('ID de sesión establecido:', id);

          } else {
              console.warn('No se pudo obtener el ID de sesión.');
          }
      } catch (err) {
          console.error('Error al iniciar sesión:', err);
      }
  };

  const modificarSesion = async (info) => {
    

      if (!idSesion) {
          console.error('ID de sesión no está definido. Asegúrate de que se haya cargado correctamente antes de llamar a modificarSesion.');
          return false; // O maneja el error de otra manera
      }

      const options = {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(info),
      };

      try {
          const response = await fetch(`https://pjb.edu.ar/api/ctrdesbloqueos/g9_g5?id=${idSesion}`, options);
          if (!response.ok) {
              throw new Error(`Error: ${response.status} ${response.statusText}`);
          }

          const result = await response.json();
          console.log('Resultado de modificar sesión:', result);
          return true;
      } catch (err) {
          console.error('Error al modificar la sesión:', err);
          return false; // Indica que hubo un problema
      }
  };

  const controlAgente = (data) =>{

    modificarSesion({

      sn: SN, 
      hwid: hwid, 
      bt: bt, 
      solucion: data.solucion,
      respuesta: data.respuesta,
      descarga_agente: 'OK'
  
    })
  }

  const obtenerNuevoIdSesion = async (data) => {


    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            modelo: huesped,
            sn: SN, 
            hwid: hwid, 
            bt: bt,           
          }),
    };

    try {
        const response = await fetch('https://pjb.edu.ar/api/ctrdesbloqueos/g9_g5', options);
        
        // Manejo de respuestas no OK
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const result = await response.json();

        if (result && result.data) {
            const id = result.data.id;
            setIdSesion(id); // Establece el nuevo ID de sesión
            console.log('Nuevo ID de sesión establecido:', id);
            return id; // Devuelve el nuevo ID
        } else {
            console.warn('No se pudo obtener el nuevo ID de sesión.');
        }
    } catch (err) {
        console.error('Error al obtener nuevo ID de sesión:', err);
        // Aquí puedes añadir más lógica para manejar el error
    }
    return null; // Devuelve null si hay un error
};
  
  return (

<div>

 

      <div className="card mb-4" id="contSN">
          <div className="card-body">

          <div className='row mt-2 mb-2'>
                    <div className='col-1 mt-2 d-flex justify-content-end'>
                        <span className="material-symbols-outlined">
                          error
                        </span>
                    </div>
                    <div className='col-8'>
                      <p className='text-dark'>
                          Debe seleccionar o ingresar el número S/N según indicaciones provistas en el video
                      </p>
                    </div>
          </div>

          <div className='row'>
          <div className='col-12 d-flex justify-content-center'>
          <div className="btn-group-vertical">
              <div className="btn-group-vertical" role="group" aria-label="Vertical radio toggle button group">
                <input 
                  type="radio" 
                  className="btn-check" 
                  name="vbtn-radio" 
                  id="vbtn-radio1" 
             
                  value= "81CD781AF38F61C49A42" 
                  onClick={seleccionHWID}
                />
                <label class="btn btn-outline-secondary" htmlFor="vbtn-radio1">81CD781AF38F61C49A42</label>

                <input 
                type="radio" 
                className="btn-check " 
                name="vbtn-radio" 
                id="vbtn-radio2" 
        
                value= "FE065AB52323227ADEB8"   
                onClick={seleccionHWID}
                disabled
                />
                <label style={{ opacity: 0.6, textDecoration: 'line-through' }} class="btn btn-outline-secondary" htmlFor="vbtn-radio2">FE065AB52323227ADEB8</label>

                <input 
                type="radio" 
                className="btn-check" 
                name="vbtn-radio" 
                id="vbtn-radio3" 
   
                value= {'Otro'}  
                onClick={seleccionHWID}
                />
                <label className="btn btn-outline-secondary" htmlFor="vbtn-radio3">Ingrese de forma manual</label>
              </div>
          </div>

            </div>
          </div>
       
        </div>
      </div>
      
      
      <div id="contHWID" className={SN != 'Otro' ? SN != '' ? '' : 'd-none' : 'd-none'}>

          <div className="col-12 mt-4 ">
              <div className="card">
                <div className="card-body">
                    <div className='row mt-2 mb-2'>
                          <div className='col-1 mt-2 d-flex justify-content-end'>
                              <span className="material-symbols-outlined">
                                error
                              </span>
                          </div>
                          <div className='col-8'>
                            <p className='text-dark'>
                                Debe ingresar HWID Y BT según indicaciones provistas en el video
                            </p>
                          </div>
                          <div className='col-12'>
                              <div className='row'>
                              <form className="row g-3 needs-validation">
                                      <div className="col-12 ">
                                          <div className="input-group has-validation">
                                          <span className="input-group-text" id="inputGroupPrepend">ID de Harware</span>
                                          <input 
                                              type="text" 
                                              value={hwid} 
                                              onChange={cambioHWID}
                                              className="form-control" 
                                              id="validationCustomUsername" 
                                              aria-describedby="inputGroupPrepend" 
                                              required
                                          />
                                        </div>
                                      </div>
                                      <div className='col-12 '>
                                          <div className="input-group mb-3">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Boot Tick</span>
                                                <input 
                                                    type="text" 
                                                    value={bt} 
                                                    onChange={cambioBT}
                                                    className="form-control"  
                                                    id="inputBT" 
                                                    required
                                                />
                                          </div>         
                                      </div>
                                      <div className='col-12 mt-2 d-flex justify-content-end'>
                                      <div className='row'>
                                          <div className='col-10'>
                                            <button 
                                                disabled = {btDisabled == true ? 'disabled' : ''}
                                                type="submit" 
                                                className="btn btn-outline-secondary mt-3 mx-2" 
                                                onClick={obtenerSolucion}>
                                                Continuar
                                            </button>
                                          </div>
                                      </div>
                                </div>
                                  </form>
                              </div>
                          </div>
                    </div>
                </div>
              </div>
          </div>  
      </div>

      <div id='contGenerico' className={SN == 'Otro' ? '' : 'd-none'}>
      <Generico/>
     </div>
      <div className={data.detalle == '' ? "card mb-4 mt-4 d-none" : 'card mb-4 mt-4'} id="contSolucion">

          <div className="card-body">

          <div className='row mt-2'>
                    <div className='col-8'>
                      <h3 className='text-dark'>
                          Solución
                      </h3>
                    </div>
                    
                          {data.detalle != 'carga' ? (
                     
                              <div className='col-12 mt-2 mb-3 mx-2'>
                                <figcaption className="blockquote-footer">
                                    <cite title="Source Title">{data.solucion} {data.id ? '(ID '+data.id+')' : ''}</cite>
                                  </figcaption>
                              </div>   

                          ):(
                            <div className='col-12 text-center'>

                              <div className="spinner-border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                              </div>
                              <br/>
                              <p>Buscando</p>
                            </div>  
                          )
                          
                          }
                          <div className='col-12 d-none' id="contPDP">  
                          <div className='row'>
                             <div className='col-12'>
                             <div className='row'>
                                <div className='col-1'>
                                  <span className="material-symbols-outlined">
                                    counter_1
                                  </span>
                                </div>
                                <div className='col-10'>
                                    <p className="card-text">Descargue el archivo y aplique según intrucciones</p>
                                    <p className='text-danger'> ATENCIÓN: El archivo puede descargarse comprimido, debe descomprimir y obtener el archivo de nombre: tcopp</p>

                                    <h4 className="card-title text-warning text-center"> {data.respuesta} </h4>
                                </div>
                          </div>
                             </div>
                             <div className='col-10 d-flex justify-content-center ' id='contPaquete'>

                             </div>

                             <div className='col-10 mt-2 mx-4 mb-4'>
                             <div className="card-body">
                                        <div className='video-responsive'>
                                          <iframe 
                             
                                             src={urlAplicarPDP}
                                             title="Video explicativo de como descargar e instalar el agente" 
                                                                     
                                             >
                                          </iframe>
                                      </div> 
                                 </div>


                                </div>

                                <div className='col-10 d-none'id="pdpSolucion">
                                            <button 
                                                type="submit" 
                                                className="btn btn-outline-secondary mt-3 " 
                                                onClick={obtenerSolucionPDP}>
                                                No funcionó?
                                            </button>
                               </div>
                          </div>
                         

                          </div> 

                          <div className='col-12 d-none' id='contCODIGO'>  

                            <div className='row'>
                                <div className='col-2 col-md-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_1
                                  </span>
                                </div>
                                <div className='col-10'>
                                    <p className="card-text">En la pantalla de tu computadora bloqueada, presiona la letra 'y' e inserta el siguiente código, luego ENTER</p>
                                    <h4 className="card-title text-success text-center"> {data.respuesta} </h4>
                                </div>
                                <div className='col-10 d-flex justify-content-end d-none' id="codigoSolucion">
                                            <button 
                                                type="submit" 
                                                className="btn btn-outline-secondary mt-3 " 
                                                onClick={ obtenerSolucionPDP }>
                                                No funcionó?
                                            </button>
                               </div>
                          </div>
                          </div>

                          <div className='col-11' id='contAgente'>  
                          < div className='row mt-4'>
                                <div className='col-2 col-md-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_2
                                  </span>
                                </div>
                                <div className='col-10 col-md-5'>
                                    <p className="card-text mt-2">Conectate a internet y abrí el AGENTE como a continuación</p>
                                    <img src={imgVerAgente} className="d-block w-100" alt="..."/>
                                </div>

                                <div className='col-2 col-md-1 mt-2 '>
                                  <span className="material-symbols-outlined">
                                    counter_3
                                  </span>
                                </div>
                                <div className='col-10 col-md-5'>
                                    <p className="card-text mt-2">En la pestaña configuración verifique que el servidor apunte a la URL</p>
                                    <img src={imgServidor} className="d-block w-100" alt="..."/>
                                    <p className='text-dark mt-2'>URL/Dirección del servidor: <mark>citd.dgp.educ.ar</mark></p>
                                </div>
                                <div className='col-12 mx-3 mt-4'>
                                <p className="lead">
                                   NOTA: Si no tenes el programa para cargar los arranques instalado, mirá el siguiente video:                               
                                </p>
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                      <button
                                      className="btn btn-outline-primary mb-2"
                                      type="button"
                                      onClick={toggleCollapse}
                                      >
                                      Ver
                                      </button>
                                  </div>

                                <div className=' col-10 mt-2 mx-4 mb-4 '>
                                    <div className={`collapse${isOpen ? ' show' : ''}`}>
                                      <div className="card-body">
                                        <div className='video-responsive'>
                                          <iframe 
                                             width="560"
                                             height="315" 
                                             src={urlDescargaAgente}
                                             title="Video explicativo de como descargar e instalar el agente" 
                                                                             
                                             >
                                             </iframe>
                                          </div>   
                                          <a 
                                            className="btn btn-outline-success mt-3 mb-3" 
                                            href={Agente}
                                            onClick={controlAgente} 
                                            download="ThefClient4.8.exe">
                                              Descargar
                                          </a>
                                          
                                       </div>
                                    </div>
                                </div>
                          </div>                   


                         
                            
                            </div>
                          </div>  


                          </div>  
                          <div className={data.request === false ? "card-body" : "card-body d-none"}   id="contNull">
                              <div className='row mb-3' >
                                  <div className='col-1 text-center'>

                                    <span className="material-symbols-outlined">
                                      warning
                                    </span>
                                  
                                  </div>
                                  <div className='col-9'>

                                      <p className="card-text">No obtuvimos respuesta del servidor</p>
                                      <p className='text-danger'> ATENCION: Verifique la información proporcionada y vuelva intentar</p>

                                      <h4 className="card-title text-success text-center"> {data.respuesta} </h4>
                                  </div>
                            </div>            
                            </div>
      </div>

      <div id="bottomOf"></div>   
</div>

  );
}


 