
function Mensaje(){

    return(
        <div>

    <div className='card mt-2'>
       <div className="card-header">
         Felices fiestas!
       </div>
        <div className="card-body">
             <div className="col-12 d-flex justify-content-center">
             <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/BJ7709gS-3M?si=cNbK60r4GD-PxTR0&autoplay=1" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" 
                allowfullscreen>                
                </iframe>
              </div>
             </div>

        </div>



        <div className='card mt-3'>
        <div className="card-body">
              <div className='col-1 '>
                  <span className="material-symbols-outlined">
                    warning
                  </span>
              </div>

              <div className='col-11'>
                 <p className='text-danger'> IMPORTANTE! Para continuar, debe seleccionar en el menú que tipo de computadora desea desbloquear.</p>
             </div>

        
        </div>
        </div> 
        

       
      </div>
    )
}

export default Mensaje;